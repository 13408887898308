<template>
  <BrowseCsvDownloads></BrowseCsvDownloads>
</template>

<script>
import BrowseCsvDownloads from "@/components/reports/BrowseCsvDownloads";
export default {
  name: "Browse",
  components: { BrowseCsvDownloads }
};
</script>

<style scoped></style>
