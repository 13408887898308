<template>
  <Page>
    <v-form v-if="hasMultipleClients" @submit.prevent="">
      <v-row dense>
        <v-col>
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
          />
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="customAttrs" :options.sync="options">
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editCsv(item)" v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit CSV Download</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <confirm-dialog ref="confirm"></confirm-dialog>
            <v-icon @click="showDeleteConfirm(item.id)" v-bind="attrs" v-on="on"
              >mdi-delete</v-icon
            >
          </template>
          <span>Delete CSV Download</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-btn color="primary" @click="addCsv">Add Custom CSV Download</v-btn>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapGetters, mapState } from "vuex";
import ConfirmDialog from "@/components/utility/ConfirmDialog";
import dataTablePagination from "@/mixins/dataTablePagination";
export default {
  name: "BrowseCsvDownloads",
  components: { ConfirmDialog, Page },
  mixins: [dataTablePagination],
  data() {
    return {
      loading: true,
      showDeleteDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Download Name", value: "name" },
        { text: "Download Description", value: "description" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        params: {
          client_id: ""
        }
      }
    };
  },
  computed: {
    ...mapState("csvs", { csvs: "items" }),
    ...mapGetters("clients", ["hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    customAttrs() {
      return {
        ...this.defaultAttrs,
        noDataText: "You have no custom CSV downloads.",
        noResultsText: "No matching custom CSV downloads",
        "footer-props": {
          itemsPerPageText: "Rows count",
          pageText: "Navigate Pages",
          itemsPerPageAllText: "All"
        }
      };
    }
  },
  methods: {
    ...mapActions("csvs", ["getPaginated", "deleteCsv"]),
    addCsv() {
      this.$router.push("/reports/custom-csvs/add");
    },
    editCsv(item) {
      this.$router.push(`/reports/custom-csvs/edit/${item.id}`);
    },
    showDeleteConfirm(id) {
      this.$refs.confirm
        .open(
          "Delete",
          "Are you sure you want to delete this custom CSV download?",
          {}
        )
        .then(confirm => {
          if (!confirm || !id) return;
          this.loading = true;
          this.deleteCsv(id).then(() => {
            this.getPaginatedProxy();
            this.loading = false;
          });
        });
    }
  }
};
</script>

<style>
td.v-data-table__mobile-row {
  max-width: 88vw;
}
.v-data-table__mobile-row__cell {
  max-width: 75vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
